<style scoped>
.layout{
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}
.layout-logo{
    width: 100px;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}
.layout-nav{
    width: 420px;
    margin: 0 auto;
    margin-right: 20px;
}
.layout-footer-center{
    text-align: center;
}
</style>
<template>
    <div class="layout">
        <Layout>
                <div mode="horizontal" theme="dark" active-name="1" style="padding:0 50px">
                    <loginfobar />
                </div>
            <Layout :style="{padding: '0 50px'}">
                <Breadcrumb :style="{margin: '16px 0'}">
                    <BreadcrumbItem >首页</BreadcrumbItem>
                      
                    <BreadcrumbItem  v-for="(v) in menupathcompute" v-bind:key="v.name">{{v.menutext}}</BreadcrumbItem>
                    
                </Breadcrumb>
                <Content :style="{padding: '0px 0', minHeight: '280px', background: '#fff'}">
                    <Layout>
                        <Sider hide-trigger :style="{background: '#fff'}">
                            <Menu  theme="light" ref="controlMenu" width="auto" :active-name="currentMenuName" :open-names="['1','4']" @on-select="handleSelect">
                                <MenuItem name="0">
                                    <Icon type="ios-home"></Icon>
                                    <span>{{getMenutext('0')}}</span>
                                </MenuItem>                              
                              <Submenu name="1" v-if="userlevel>=100">
                                <template slot="title">
                                    <Icon type="ios-analytics" />
                                    {{getMenutext('1')}}
                                </template>                                  
                                <MenuItem name="1-1">
                                    <Icon type="ios-list"></Icon>
                                    <span>{{getMenutext('1-1')}}</span>
                                </MenuItem>
<!--                               <MenuItem name="1-2">
                                    <Icon type="ios-card-outline"></Icon>
                                    <span>{{getMenutext('1-2')}}</span>
                                </MenuItem>
                                --> 
                                <MenuItem name="erp-config">
                                    <Icon type="ios-settings"></Icon>
                                    <span>{{getMenutext('erp-config')}}</span>
                                </MenuItem>
                                <MenuItem name="1-3">
                                    <Icon type="ios-settings"></Icon>
                                    <span>{{getMenutext('1-3')}}</span>
                                </MenuItem>
                                <MenuItem name="1-4">
                                    <Icon type="ios-settings"></Icon>
                                    <span>{{getMenutext('1-4')}}</span>
                                </MenuItem>
                                <MenuItem name="1-5">
                                    <Icon type="ios-settings"></Icon>
                                    <span>{{getMenutext('1-5')}}</span>
                                </MenuItem>
                                <MenuItem name="companyinfo">
                                    <Icon type="ios-settings"></Icon>
                                    <span>{{getMenutext('companyinfo')}}</span>
                                </MenuItem>
                              </Submenu>
                              <Submenu name="4" v-if="userlevel>0 && userlevel<=50">
                                <template slot="title">
                                    <Icon type="ios-analytics" />
                                    {{getMenutext('4')}}
                                </template>
                                <MenuItem name="4-1">
                                  <Icon type="ios-list"></Icon>
                                  <span>{{getMenutext('4-1')}}</span>
                                </MenuItem>
                                <MenuItem name="4-2">
                                  <Icon type="ios-list"></Icon>
                                  <span>{{getMenutext('4-2')}}</span>
                                </MenuItem>
                                <MenuItem name="4-3" v-if="userlevel>0 && userlevel<=10">
                                  <Icon type="ios-list"></Icon>
                                  <span>{{getMenutext('4-3')}}</span>
                                </MenuItem>       
                                <MenuItem name="modifypassword">
                                    <Icon type="ios-list"></Icon>
                                    <span>{{getMenutext('modifypassword')}}</span>
                                  </MenuItem>   
                                <MenuItem name="4-4">
                                  <Icon type="ios-list"></Icon>
                                  <span>{{getMenutext('4-4')}}</span>
                                </MenuItem>    
                              </Submenu>
                              <Submenu name="5" v-if="userlevel>0 && userlevel<=50">
                                <template slot="title">
                                    <Icon type="ios-analytics" />
                                    {{getMenutext('5')}}
                                </template>
                                <MenuItem name="checkcompany">
                                    <Icon type="ios-list"></Icon>
                                    <span>{{getMenutext('checkcompany')}}</span>
                                </MenuItem>                                  
                                <MenuItem name="5-0">
                                    <Icon type="ios-list"></Icon>
                                    <span>{{getMenutext('5-0')}}</span>
                                </MenuItem>   
                                <Submenu name="5-1">
                                    <template slot="title">
                                        <Icon type="ios-analytics" />
                                        {{getMenutext('5-1')}}
                                    </template>
                                    <MenuItem name="standard">
                                        <Icon type="ios-list"></Icon>
                                        <span>{{getMenutext('standard')}}</span>
                                    </MenuItem>                                  
                                    <MenuItem name="filetemplatemanage">
                                        <Icon type="ios-list"></Icon>
                                        <span>{{getMenutext('filetemplatemanage')}}</span>
                                    </MenuItem>   
                                </Submenu>    
                            </Submenu> 
                            </Menu>
                        </Sider>
                        <Content :style="{padding: '6px', minHeight: '280px', background: '#fff'}">
                            <router-view></router-view>
                        </Content>
                    </Layout>
                </Content>
            </Layout>
            <!--
            <Footer class="layout-footer-center">2020-2029 &copy; 天津智算科技有限责任公司</Footer>
              -->
              <Footer class="layout-footer-center"></Footer>
        </Layout>
    </div>
</template>

<script>
    import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
    export default {
        name:'WorkDesk',
        data () {
          return {
            currentMenuName:'0',
            userlevel:this._self.$root.$store.state.LogonInfo.level,
            menupathJSON:[
                {path:'/workdesk/AdminHome',name:'0',menutext:'工作台首页'},
                {path:'/workdesk/VehicleList',name:'1',menutext:'商家功能'},
                {path:'/workdesk/VehicleList',name:'1-1',menutext:'整车编码列表'},
                {path:'/workdesk/ErpConfig',name:'erp-config',menutext:'ERP配置'},
                {path:'/workdesk/CertificateTemplateHome/CertificateTemplate',name:'1-2',menutext:'合格证模板'},
                {path:'/workdesk/CertificateTemplateHome/CertificateEdit',name:'1-2-1',belongMenu:'1-2',menutext:'添加合格证'}, 
                {path:'/workdesk/SubAccount',name:'1-3',menutext:'子账号设置'},
                {path:'/workdesk/SealSetting',name:'1-4',menutext:'印章图管理'},
                {path:'/workdesk/WaterMarkSetting',name:'1-5',menutext:'水印图管理'},
                {path:'/workdesk/CompanyInfo',name:'companyinfo',menutext:'信息修改'},
                {path:'/workdesk/SuperAdmin',name:'4',menutext:'商家管理'},
                {path:'/workdesk/SuperAdmin',name:'4-1',menutext:'商家列表'},
                {path:'/workdesk/SuperAdmin/CccSetting',name:'4-2',menutext:'认证管理'},
                {path:'/workdesk/SuperAdmin/SelfAccount',name:'4-3',menutext:'账号管理'},   
                {path:'/workdesk/SuperAdmin/ModifyPassword',name:'modifypassword',menutext:'修改密码'},   
                {path:'/workdesk/SuperAdmin/SupportSetting',name:'4-4',menutext:'信息维护'},   
                {path:'/workdesk/SuperAdmin/CheckSys',name:'5',menutext:'认证系统管理'},
                {path:'/workdesk/SuperAdmin/CheckCompany',name:'checkcompany',menutext:'功能开通管理'},
                {path:'/workdesk/SuperAdmin/CheckAccount',name:'5-0',menutext:'认证机构账号'},
                {path:'/workdesk/SuperAdmin/CheckFileTemplate',name:'5-1',menutext:'电动自行车'}, 
                {path:'/workdesk/SuperAdmin/CheckFileTemplate',name:'filetemplatemanage',menutext:'模板维护'}, 
                {path:'/workdesk/SuperAdmin/checkdept/standard',name:'standard',menutext:'标准文档'}, 
            ]
          }
        },
        components:{
            loginfobar:UserInfoTitleBar,
        },
        mounted(){
            this.$nextTick(()=>{
                this.currentMenuName = this.getMenuName(this.$route.path)
                this.$refs.controlMenu.updateActiveName()
            })
        },
        computed:{
            menupathcompute() {
                return this.menupathJSON.filter(v=>{
                        if(v.path == this.$route.path){
                            return true;
                        }
                    });
            }
        },
        watch: {
            $route: {
                handler: function(val, oldVal){
                    this.currentMenuName = this.getMenuName(val.path)
                },
                // 深度观察监听
                deep: true
            }
            },
        methods:{
          getMenutext:function(name){
              for(var v in this.menupathJSON){
                  if(this.menupathJSON[v].name == name){
                      return this.menupathJSON[v].menutext
                  }
              }
          },  
          getMenuName:function(path){
              var result=null;
              for(var v in this.menupathJSON){
                  if(this.menupathJSON[v].path == path){
                        if(this.menupathJSON[v].belongMenu !=null){
                            result = this.menupathJSON[v].belongMenu
                        }
                        else{
                            result = this.menupathJSON[v].name
                        }
                        return result;
                  }
              }
          },  
          handleSelect:function(name){
            for(var v in this.menupathJSON){
                    if(this.menupathJSON[v].name == name){
                        this.$router.push(this.menupathJSON[v].path);
                        break;
                    }
                }
          }
        }
    }
</script>

<style scoped>
    .ivu-menu-item {
        margin-left: 0px;
        text-align: left;
    }

    .ivu-menu-submenu{
        margin-left: 0px;
        text-align: left;
    }

    .ivu-menu-vertical .ivu-menu-item, .ivu-menu-vertical .ivu-menu-submenu-title {
        margin-left: 0px;
        text-align: left;
    }
</style>
